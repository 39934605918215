



















import { Component, Vue } from 'vue-property-decorator';
import BarChart from '@/components/charts/barChart';
import { getComponent } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import MainTimeOption from '@/interfaces/mainTimeOption';  // eslint-disable-line
import dashboardAppsyncClient from '@/lib/appsync/dashboard';
import getInternalMetrics from '@/graphql/getInternalMetrics.graphql';
import getReliabilityMetric from '@/graphql/getReliabilityMetric.graphql'; // eslint-disable-line

const appModule = namespace('appModule');

@Component({
  components: {
    BarChart,
    GeneralLayout: () => getComponent('common/GeneralLayout'),
  },
})
export default class CentralLogging extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public async fetchData(): Promise<void> {
    const {
      data:
        {
          get_internal_metrics: {
            job_count_by_prediction_type_dict: predictionTypeDict,
            job_count_by_user_dict: userDict,
            job_count_by_user_entries_dict: userEntriesDict,
          },
        },
    } = await dashboardAppsyncClient.query({
      query: getInternalMetrics,
      variables: {
        env: 'prod',
        start_date: new Date(
          new Date().getTime() - this.currentTimeOption.value * 86400000,
        ).toISOString(),
        end_date: new Date(new Date()).toISOString(),
      },
    });
    /* eslint-disable no-console */
    console.log(predictionTypeDict);
    console.log(userDict);
    console.log(userEntriesDict);
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
